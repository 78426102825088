export default function changeTextMultiselect() {
  let arrSpan = document.querySelectorAll(
    ".multiselect__option:not([data-select])"
  );

  if (arrSpan.length) {
    arrSpan.forEach((el, idx) => {
      if (idx === 0 || idx % 2 === 0) {
        el.innerHTML = this.$i18n.t("label.changeQuery");
      } else {
        el.innerHTML = this.$i18n.t("label.listEmpty");
      }
    });
  }
}
