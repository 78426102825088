var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{class:{
    'cols-12 col-sm-7 col-md-5 col-lg-4': !_vm.sizeFull,
    'px-0 pb-2': _vm.sizeFull
  }},[_vm._l((_vm.stackAddress),function(address,idx){return _c('div',{key:idx,class:{
      'mb-6': (!address.isTouched && !address.value) || address.value,
      'mb-2': address.isTouched && !address.value
    },attrs:{"id":"select-wrap"}},[_c('Multiselect',{class:{ invalid: address.isTouched && !address.value },attrs:{"value":address.value,"options":address.options,"close-on-select":true,"hide-selected":false,"preserve-search":false,"taggable":address.isTaggable,"label":"label","track-by":"desc","select-label":"","selected-label":"","deselect-label":"","tag-placeholder":_vm.$t('label.addBuilding'),"placeholder":_vm.$t('label.search'),"disabled":_vm.loading || _vm.load},on:{"remove":_vm.updatePropsAddress,"input":_vm.setNewBuilding,"select":_vm.createRowAddress,"tag":_vm.createNewBuilding,"close":function($event){address.isTouched = true}},scopedSlots:_vm._u([{key:"singleLabel",fn:function(props){return [_c('span',{staticClass:"option__label mr-1"},[_vm._v(" "+_vm._s(props.option.nodeTypeName)+" ")]),_c('span',{staticClass:"option__text"},[_vm._v(_vm._s(props.option.desc))])]}},{key:"option",fn:function(props){return [_c('v-icon',{attrs:{"color":"privat"}},[_vm._v("mdi-check")]),_c('span',{staticClass:"option__label mr-1"},[_vm._v(" "+_vm._s(props.option.nodeTypeName)+" ")]),_c('span',{staticClass:"option__text",attrs:{"title":!address.isTaggable && props.option.desc.length > 40
              ? props.option.desc
              : false}},[_vm._v(" "+_vm._s(props.option.desc)+" ")]),_c('v-icon',{staticClass:"option__close",attrs:{"color":"red darken-3"}},[_vm._v("mdi-close")])]}}],null,true)},[_c('template',{slot:"placeholder"},[_vm._v(_vm._s(address.label))])],2),_c('span',{staticClass:"single__legend",class:{ hide: address.value }},[_vm._v(" "+_vm._s(address.label)+" ")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(address.isTouched && !address.value),expression:"address.isTouched && !address.value"}],staticClass:"typo__label error--text"},[_vm._v(" "+_vm._s(_vm.$t("rules.required"))+" ")]),(!address.value && _vm.load)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"privat"}}):_vm._e()],1)}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isBuildings),expression:"isBuildings"}],attrs:{"id":"corpus"}},[_c('v-text-field',{attrs:{"label":_vm.$t('label.corpus'),"disabled":_vm.loading,"color":"privat","outlined":"","dense":""},on:{"input":function($event){return _vm.$emit('getAddrId', _vm.modelAd)}},model:{value:(_vm.modelAd.corpus),callback:function ($$v) {_vm.$set(_vm.modelAd, "corpus", $$v)},expression:"modelAd.corpus"}})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }