<template>
  <div v-if="calcPledge" id="total">
    <v-card class="total-box d-none d-lg-block px-2" elevation="1" width="450">
      <v-card-title>
        {{ $t("offer.total.total") }} {{ totalSubject }}
      </v-card-title>

      <template v-if="financingCR">
        <OfferTotalCR :calc-pledge="calcPledge" />
      </template>

      <template v-else-if="financing579">
        <OfferTotalFL579 :calc-pledge="calcPledge" />
      </template>

      <template v-else>
        <v-card-text class="py-1">
          {{ $t("offer.total.totalCostPFReg") }}
          <span class="text--black">
            {{ costFilter(calcPledge.totalCostPFReg, coastFormat) }}
          </span>
        </v-card-text>
        <v-card-text class="py-1 pl-7">
          {{ $t("offer.total.totalCost") }}
          <span class="text--black">
            {{ costFilter(calcPledge.totalCost, coastFormat) }}
          </span>
        </v-card-text>
        <v-card-text class="py-1 pl-7">
          {{ $t("offer.total.totalReg") }}
          <span class="text--black">
            {{ costFilter(calcPledge.totalReg, coastFormat) }}
          </span>
        </v-card-text>
        <v-card-text class="pt-1 pb-4 pl-7">
          <span class="text--label">
            {{ $t("offer.total.totalPF") }}
          </span>
          <span class="text--black">
            {{ costFilter(calcPledge.totalPF, coastFormat) }}
          </span>
        </v-card-text>

        <v-card-text class="py-1 pb-4">
          <span>
            {{ $t("offer.total.prepayment") }}
          </span>
          <span class="text--black">
            {{ costFilter(calcPledge.currencyTotalPrepayment, coastFormat) }}
          </span>
        </v-card-text>

        <v-card-text class="py-1">
          {{ $t("offer.total.totalFunding") }}
          <span class="text--black">
            {{ costFilter(calcPledge.totalFunding, coastFormat) }}
          </span>
        </v-card-text>
        <v-card-text class="py-1 pl-7">
          {{ $t("offer.total.totalCost") }}
          <span class="text--black">
            {{ costFilter(calcPledge.totalWithoutPrep, coastFormat) }}
          </span>
        </v-card-text>
        <v-card-text class="py-1 pl-7">
          {{ $t("offer.total.totalReg") }}
          <span class="text--black">
            {{ costFilter(calcPledge.totalReg, coastFormat) }}
          </span>
        </v-card-text>
        <v-card-text class="py-1 pl-7">
          <span class="text--label">
            {{ $t("offer.total.totalPF") }}
          </span>
          <span class="text--black">
            {{ costFilter(calcPledge.totalPF, coastFormat) }}
          </span>
        </v-card-text>
        <v-divider
          v-if="calcPledge.discountBankCurrency || calcPledge.monthCommission"
          class="ma-3"
        ></v-divider>
        <v-card-text v-if="calcPledge.discountBankCurrency" class="py-1">
          {{ $t("offer.total.lowerPercent") }}
          <span class="text--black" style="min-width: 45%">
            {{ costFilter(calcPledge.discountBankCurrency, coastFormat) }}
            <span v-if="calcPledge.totalDiscount">
              {{ `(${calcPledge.totalDiscount} %)` }}
            </span>
          </span>
        </v-card-text>
        <v-card-text v-if="calcPledge.monthCommission" class="pt-1 pb-4">
          {{ $t("offer.total.monthCommission") }}
          <span class="text--black" style="min-width: 45%">
            {{ costFilter(calcPledge.monthCommission.commSum, coastFormat) }}
            <span v-if="calcPledge.monthCommission.commPercent">
              {{ `(${calcPledge.monthCommission.commPercent} %)` }}
            </span>
          </span>
        </v-card-text>
      </template>
    </v-card>
    <v-speed-dial
      class="d-lg-none"
      v-model="showFab"
      bottom
      right
      fixed
      direction="left"
      transition="slide-x-reverse-transition"
    >
      <template v-slot:activator>
        <v-btn v-model="showFab" color="teal darken-2" dark fab small>
          <v-icon v-if="showFab">
            mdi-close
          </v-icon>
          <v-icon v-else>
            mdi-blur-linear
          </v-icon>
        </v-btn>
      </template>

      <v-card class="total-box px-2" elevation="1" width="450">
        <v-card-title>
          {{ $t("offer.total.total") }} {{ totalSubject }}
        </v-card-title>

        <template v-if="financingCR">
          <OfferTotalCR :calc-pledge="calcPledge" />
        </template>

        <template v-else-if="financing579">
          <OfferTotalFL579 :calc-pledge="calcPledge" />
        </template>

        <template v-else>
          <v-card-text class="py-1">
            {{ $t("offer.total.totalCostPFReg") }}
            <span class="text--black">
              {{ costFilter(calcPledge.totalCostPFReg, coastFormat) }}
            </span>
          </v-card-text>
          <v-card-text class="py-1 pl-7">
            {{ $t("offer.total.totalCost") }}
            <span class="text--black">
              {{ costFilter(calcPledge.totalCost, coastFormat) }}
            </span>
          </v-card-text>
          <v-card-text class="py-1 pl-7">
            {{ $t("offer.total.totalReg") }}
            <span class="text--black">
              {{ costFilter(calcPledge.totalReg, coastFormat) }}
            </span>
          </v-card-text>
          <v-card-text class="pt-1 pb-4 pl-7">
            <span class="text--label">
              {{ $t("offer.total.totalPF") }}
            </span>
            <span class="text--black">
              {{ costFilter(calcPledge.totalPF, coastFormat) }}
            </span>
          </v-card-text>

          <v-card-text class="py-1 pb-4">
            <span>
              {{ $t("offer.total.prepayment") }}
            </span>
            <span class="text--black">
              {{ costFilter(calcPledge.currencyTotalPrepayment, coastFormat) }}
            </span>
          </v-card-text>

          <v-card-text class="py-1">
            {{ $t("offer.total.totalFunding") }}
            <span class="text--black">
              {{ costFilter(calcPledge.totalFunding, coastFormat) }}
            </span>
          </v-card-text>
          <v-card-text class="py-1 pl-7">
            {{ $t("offer.total.totalCost") }}
            <span class="text--black">
              {{ costFilter(calcPledge.totalWithoutPrep, coastFormat) }}
            </span>
          </v-card-text>
          <v-card-text class="py-1 pl-7">
            {{ $t("offer.total.totalReg") }}
            <span class="text--black">
              {{ costFilter(calcPledge.totalReg, coastFormat) }}
            </span>
          </v-card-text>
          <v-card-text class="py-1 pl-7">
            <span class="text--label">
              {{ $t("offer.total.totalPF") }}
            </span>
            <span class="text--black">
              {{ costFilter(calcPledge.totalPF, coastFormat) }}
            </span>
          </v-card-text>
          <v-divider
            v-if="calcPledge.discountBankCurrency || calcPledge.monthCommission"
            class="ma-3"
          ></v-divider>
          <v-card-text v-if="calcPledge.discountBankCurrency" class="py-1">
            {{ $t("offer.total.lowerPercent") }}
            <span class="text--black" style="min-width: 45%">
              {{ costFilter(calcPledge.discountBankCurrency, coastFormat) }}
              <span v-if="calcPledge.totalDiscount">
                {{ `(${calcPledge.totalDiscount} %)` }}
              </span>
            </span>
          </v-card-text>
          <v-card-text v-if="calcPledge.monthCommission" class="pt-1 pb-4">
            {{ $t("offer.total.monthCommission") }}
            <span class="text--black" style="min-width: 45%">
              {{ costFilter(calcPledge.monthCommission.commSum, coastFormat) }}
              <span v-if="calcPledge.monthCommission.commPercent">
                {{ `(${calcPledge.monthCommission.commPercent} %)` }}
              </span>
            </span>
          </v-card-text>
        </template>
      </v-card>
    </v-speed-dial>
  </div>
</template>

<script>
import costFilter from "@/filters/cost.filter";
import OfferTotalCR from "./OfferTotalCR";
import OfferTotalFL579 from "./OfferTotalFL579";

export default {
  name: "OfferTotal",
  props: {
    calcPledge: {
      type: Object,
      default: null
    },
    length: {
      type: Number,
      default: 1
    },
    program: {
      type: String
    }
  },
  data() {
    return {
      showFab: false,
      coastFormat: "uk-UA"
    };
  },
  components: {
    OfferTotalCR,
    OfferTotalFL579
  },
  computed: {
    totalSubject() {
      let length = this.length;
      let str = "";

      if (this.financingCR) {
        return `(${this.$i18n.t(`offer.total.estate`, { count: length })})`;
      }

      if (length === 5) {
        str = `(${length} ${this.$i18n.t(`offer.total.subject_5`)})`;
      } else if (length === 1) {
        str = `(${length} ${this.$i18n.t(`offer.total.subject_1`)})`;
      } else {
        str = `(${length} ${this.$i18n.t(`offer.total.subject_2`)})`;
      }

      return str;
    },
    financing579() {
      return this.program === "FL_579";
    },
    financingCR() {
      return this.program.includes("CR");
    }
  },
  methods: {
    costFilter: costFilter
  },
  watch: {
    calcPledge: {
      deep: true,
      handler() {
        this.showFab = true;
      }
    }
  }
};
</script>

<style lang="scss">
#total {
  .v-card {
    &.total-box {
      position: fixed;
      top: 180px;
      right: 50px;
      border: 1px solid rgba(0, 0, 0, 0.15);
    }
  }
  .v-card__text {
    font-size: 15px;
    display: flex;
    justify-content: space-between;
    color: rgba(0, 0, 0, 0.6);
    .text--label {
      width: 60%;
    }
    .text--black {
      width: 40%;
      text-align: right;
      align-self: end;
      color: rgba(0, 0, 0, 0.96);
    }
    .text--lowercase {
      text-transform: lowercase;
    }
  }
  .v-speed-dial {
    position: fixed;
    top: 200px;
    right: 15px;
    height: 40px;
    .v-card.total-box {
      top: 215px;
      right: 70px;
    }
  }

  @media (max-width: 385px) {
    .v-card__title {
      font-size: 15px;
    }
    .v-card__text {
      font-size: 14px;
    }
  }
}
</style>
