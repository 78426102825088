<template>
  <div>
    <v-card-text class="py-1">
      {{ $t("offer.total.totalCostPFReg") }}
      <span class="text--black">
        {{ costFilter(calcPledge.totalCostPFReg, coastFormat) }}
      </span>
    </v-card-text>
    <v-card-text class="py-1 pl-7">
      {{ $t("offer.total.totalCost") }}
      <span class="text--black">
        {{ costFilter(calcPledge.totalCost, coastFormat) }}
      </span>
    </v-card-text>

    <v-card-text class="py-3">
      <span>
        {{ $t("offer.total.prepaymentCR") }}
      </span>
      <span class="text--black">
        {{ costFilter(calcPledge.currencyTotalPrepayment, coastFormat) }}
      </span>
    </v-card-text>

    <v-card-text class="py-1">
      {{ $t("offer.total.totalFunding") }}
      <span class="text--black">
        {{ costFilter(calcPledge.totalFunding, coastFormat) }}
      </span>
    </v-card-text>
    <v-card-text class="py-1 pl-7 pb-5">
      {{ $t("offer.total.totalCost") }}
      <span class="text--black">
        {{ costFilter(calcPledge.totalWithoutPrep, coastFormat) }}
      </span>
    </v-card-text>
  </div>
</template>

<script>
import costFilter from "@/filters/cost.filter";

export default {
  name: "OfferTotalCR",
  props: {
    calcPledge: {
      type: Object,
      default: null
    },
    coastFormat: {
      type: String,
      default: "uk-UA"
    }
  },
  methods: {
    costFilter: costFilter
  }
};
</script>
