<template>
  <v-form
    id="typeFinancing"
    autocomplete="off"
    ref="typeFinancing"
    lazy-validation
    @submit.prevent=""
  >
    <v-container>
      <v-row>
        <v-col cols="12" class="mb-3">
          <v-radio-group
            v-model="typeFinancing"
            id="condition"
            class="mt-1 pl-0"
            dense
            column
          >
            <v-radio
              v-for="n in allTypeFinancing"
              :key="n.value"
              :label="n.label"
              :value="n.value"
              :disabled="n.disabled"
              class="mr-4"
              color="privat"
            >
              <template v-slot:label>
                <span class="mr-3">{{ n.label }}</span>
                <v-tooltip right max-width="220">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" color="grey" dense>
                      mdi-information
                    </v-icon>
                  </template>
                  <span>{{ n.description }}</span>
                </v-tooltip>
              </template>
            </v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          sm="6"
          md="5"
          lg="4"
          xl="3"
          class="d-flex justify-space-between"
        >
          <v-btn
            id="back"
            class="mr-4"
            elevation="1"
            color="blue-grey lighten-5"
            width="37%"
            height="39px"
            @click.native="$router.push('/orders')"
          >
            <span>{{ $t("action.back") }}</span>
          </v-btn>
          <v-btn
            id="continue"
            elevation="1"
            color="privat"
            width="57%"
            height="39px"
            dark
            @click.native="$emit('setFinancing', typeFinancing)"
          >
            <span>{{ $t("action.continue") }}</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
export default {
  name: "OfferStepZero",
  props: ["program"],
  data() {
    return {
      typeFinancing: this.program || "FL"
    };
  },
  computed: {
    allTypeFinancing() {
      return [
        {
          value: "FL",
          label: this.$i18n.t("offer.allTypeFinancing.FL"),
          description: this.$i18n.t("offer.tooltip.typeFinancing.FL")
        },
        {
          value: "FL_579",
          label: this.$i18n.t("offer.allTypeFinancing.FL_579"),
          description: this.$i18n.t("offer.tooltip.typeFinancing.FL_579")
        },
        {
          value: "CR",
          label: this.$i18n.t("offer.allTypeFinancing.CR"),
          description: this.$i18n.t("offer.tooltip.typeFinancing.CR")
        },
        {
          value: "CR_579",
          label: this.$i18n.t("offer.allTypeFinancing.CR_579"),
          description: this.$i18n.t("offer.tooltip.typeFinancing.CR_579")
        }
      ];
    }
  }
};
</script>
