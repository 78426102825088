import CONST from "@/utils/constants";

export default function checkFileType(val) {
  if (!val) return;

  let arrName = val.name.split(".");
  let length = arrName.length;
  let type = arrName[length - 1];

  return CONST.SPEC_FILE_TYPE.includes(type.toUpperCase());
}
