<template>
  <div>
    <v-card-text class="py-1">
      {{ $t("offer.total.totalCostPFReg") }}
      <span class="text--black">
        {{ costFilter(calcPledge.totalCostPFReg, coastFormat) }}
      </span>
    </v-card-text>
    <v-card-text class="py-1 pl-7">
      {{ $t("offer.total.totalCost") }}
      <span class="text--black">
        {{ costFilter(calcPledge.totalCost, coastFormat) }}
      </span>
    </v-card-text>
    <v-card-text class="py-1 pl-7">
      {{ $t("offer.total.totalReg") }}
      <span class="text--black">
        {{ costFilter(calcPledge.totalReg, coastFormat) }}
      </span>
    </v-card-text>
    <v-card-text class="pt-1 pb-4 pl-7">
      <span class="text--label">
        {{ $t("offer.total.totalPF") }}
      </span>
      <span class="text--black">
        {{ costFilter(calcPledge.totalPF, coastFormat) }}
      </span>
    </v-card-text>

    <v-card-text class="py-1">
      <span>
        {{ $t("offer.total.prepayment579") }}
      </span>
      <span class="text--black">
        {{ costFilter(calcPledge.currencyTotalPrepaymentPFReg, coastFormat) }}
      </span>
    </v-card-text>

    <v-card-text class="py-1 pl-7">
      <span class="text--lowercase">
        {{ $t("offer.total.prepayment") }}
      </span>
      <span class="text--black">
        {{ costFilter(calcPledge.currencyTotalPrepayment, coastFormat) }}
      </span>
    </v-card-text>
    <v-card-text class="py-1 pl-7">
      {{ $t("offer.total.totalReg") }}
      <span class="text--black">
        {{ costFilter(calcPledge.totalReg, coastFormat) }}
      </span>
    </v-card-text>
    <v-card-text class="pt-1 pb-4 pl-7">
      <span class="text--label">
        {{ $t("offer.total.totalPF") }}
      </span>
      <span class="text--black">
        {{ costFilter(calcPledge.totalPF, coastFormat) }}
      </span>
    </v-card-text>

    <v-card-text class="py-1">
      {{ $t("offer.total.totalFunding") }}
      <span class="text--black">
        {{ costFilter(calcPledge.totalFunding, coastFormat) }}
      </span>
    </v-card-text>
    <v-card-text class="py-1 pl-7 pb-2">
      {{ $t("offer.total.totalCost") }}
      <span class="text--black">
        {{ costFilter(calcPledge.totalWithoutPrep, coastFormat) }}
      </span>
    </v-card-text>
    <v-divider
      v-if="calcPledge.discountBankCurrency || calcPledge.monthCommission"
      class="ma-3"
    ></v-divider>
    <v-card-text v-if="calcPledge.discountBankCurrency" class="py-1">
      {{ $t("offer.total.lowerPercent") }}
      <span class="text--black" style="min-width: 45%">
        {{ costFilter(calcPledge.discountBankCurrency, coastFormat) }}
        <span v-if="calcPledge.totalDiscount">
          {{ `(${calcPledge.totalDiscount} %)` }}
        </span>
      </span>
    </v-card-text>
    <v-card-text v-if="calcPledge.monthCommission" class="pt-1 pb-4">
      {{ $t("offer.total.monthCommission") }}
      <span class="text--black" style="min-width: 45%">
        {{ costFilter(calcPledge.monthCommission.commSum, coastFormat) }}
        <span v-if="calcPledge.monthCommission.commPercent">
          {{ `(${calcPledge.monthCommission.commPercent} %)` }}
        </span>
      </span>
    </v-card-text>
  </div>
</template>

<script>
import costFilter from "@/filters/cost.filter";

export default {
  name: "OfferTotalFL579",
  props: {
    calcPledge: {
      type: Object,
      default: null
    },
    coastFormat: {
      type: String,
      default: "uk-UA"
    }
  },
  methods: {
    costFilter: costFilter
  }
};
</script>
