<template>
  <v-container
    id="offer"
    class="px-5 px-md-3"
    v-if="getStatus === 'create' || getStatus === 'finish'"
  >
    <v-row align="center">
      <v-col
        cols="12"
        :sm="step === 4 ? 7 : 12"
        v-if="step !== 0 && step !== 5"
      >
        <h2 class="page-title mb-1">{{ $t("offer.title") }}</h2>
        <span v-if="offer.nrmDecision" class="page-step">
          {{
            stepSellerText
              ? $t("offer.step.oneDealer")
              : stepClientNear[step - 1]
          }}
        </span>
        <span v-if="!offer.nrmDecision" class="page-step">
          {{
            stepSellerText
              ? $t("offer.step.oneDealer")
              : stepClientNotNear[step - 1]
          }}
        </span>
      </v-col>
      <v-col v-if="step === 0">
        <h2 class="page-title mb-1">{{ $t("offer.title") }}</h2>
        <span class="page-step">{{ $t("offer.step.zero") }}</span>
      </v-col>
      <v-col v-if="step === 5">
        <h2 class="d-flex align-center page-title">
          <v-icon color="green" x-large class="mr-5">
            mdi-check-circle
          </v-icon>
          {{ $t("offer.step.finish") }}
        </h2>
        <div class="text--secondary pl-15" style="font-size: 14px;">
          <template v-if="protector">
            <span class="d-block">{{ $t("offer.step.protector_text_1") }}</span>
            <span class="d-block">{{ $t("offer.step.protector_text_2") }}</span>
          </template>
          <template v-else>
            <span class="d-block">{{ $t("offer.step.finish_text_1") }}</span>
            <span class="d-block">{{ $t("offer.step.finish_text_2") }}</span>
          </template>
        </div>
      </v-col>
      <v-col
        v-if="step === 4"
        class="justify-end px-0 hidden-xs-only hidden-sm-only"
      >
        <v-btn
          id="back"
          class="mr-4"
          elevation="1"
          color="blue-grey lighten-5"
          width="37%"
          height="39px"
          aria-label="back"
          @click.native="stepBack"
        >
          <span>{{ $t("action.back") }}</span>
        </v-btn>
        <v-btn
          id="save"
          elevation="1"
          color="privat"
          width="57%"
          height="39px"
          aria-label="continue"
          @click.native="saveOffer"
          :disabled="!offer.pdfBase64"
        >
          <span class="white--text">
            {{ $t("action.save") }}
          </span>
        </v-btn>
      </v-col>
    </v-row>
    <!--<v-divider></v-divider>-->
    <div id="form-offer" ref="form">
      <offer-step-zero
        v-if="step === 0"
        :program="offer.program"
        @setFinancing="setFinancing"
      />
      <offer-step-one
        v-if="step === 1"
        :client="offer.client"
        :program="offer.program"
        :toggle-loader="loadingOptions"
        @progress="showProgress"
        @step="goToStep"
        @stepSeller="stepSellerText = true"
      />

      <offer-step-two
        v-if="step === 2"
        :pledge="offer.pledgeDetails"
        :general="offer.generalParams"
        :client="offer.client"
        :program="offer.program"
        :options="formOptions"
        :overlay="overlay"
        :cash-rate="cashRate"
        @progress="showProgress"
        @step="goToStep"
        @update="updateOffer"
        @updateFormOptions="updateFormOptions"
      />

      <offer-step-three
        v-if="step === 3"
        :nrmOptions="nrmOptions"
        :cpId="offer.client.cpId"
        :general="offer.generalParams"
        :program="offer.program"
        @progress="showProgress"
        @step="goToStep"
        @update="updateFinancing"
        @goToPdf="getPdfReport"
        @goToNrm="getNRMService"
      />

      <offer-step-four
        v-if="step === 4"
        :pdf="offer.pdfBase64"
        @save="saveOffer"
        @stepBack="stepBack"
      />

      <offer-step-five
        v-if="step === 5"
        :client="offer.client"
        :savedOffer="savedOffer"
        :load="loading"
        :pdf="offer.pdfBase64"
        :disabledSendToBank="disabledSendToBank"
        @sendToBank="checkAttachFile"
      />
      <v-overlay :z-index="0" :value="overlay" :class="{ isOpacity: overlay }">
        <v-card light class="wrap-progress">
          <v-card-text class="pa-2">
            <p class="text-h6 text-sm-h5 text-center">
              {{ $t("offer.overlay.title") }}
            </p>
          </v-card-text>
          <v-progress-linear
            v-model="progressValue"
            color="privat"
            :indeterminate="progressQuery"
            :active="progressShow"
            :query="true"
            class="mx-auto"
          ></v-progress-linear>
          <v-card-text class="py-2 px-4">
            <p class="text-subtitle-2 text-center mt-5" v-if="step !== 2">
              {{ $t("offer.overlay.dataProcessing") }}<br />
              {{
                $t("offer.overlay.timeProcessing", {
                  time: $t("offer.overlay.time_30sec")
                })
              }}
            </p>
            <p class="text-subtitle-2 text-center mt-5" v-if="step === 2">
              {{ $t("offer.overlay.individualCalculation") }}<br />
              {{
                $t("offer.overlay.timeProcessing", {
                  time: $t("offer.overlay.time_3min")
                })
              }}
            </p>
          </v-card-text>
        </v-card>
      </v-overlay>
    </div>

    <v-row justify="center" id="showAttach">
      <v-dialog
        v-model="showAttach"
        persistent
        min-width="320px"
        max-width="500px"
      >
        <v-card class="pa-3">
          <v-card-title class="text-h6 px-2 pt-2 pb-4 justify-center">
            {{ $t("offer.attachFile") }}
          </v-card-title>
          <v-card-text class="px-2 pb-0">
            <v-file-input
              v-model="offer.specification"
              id="specification"
              ref="specification"
              accept=".png, .jpg, .jpeg, .xls, .xlsx, .doc, .docx, .odt, .zip, .rar, .pdf"
              :rules="offer.specification ? rules.spec : []"
              :label="$i18n.t('label.specFile')"
              :disabled="loading"
              color="privat"
              show-size
              outlined
              dense
              @change="withOutSpecFile = false"
            ></v-file-input>
            <span class="ml-8 mb-4 d-block">{{ $t("label.fileFormat") }}</span>
            <v-checkbox
              v-model="withOutSpecFile"
              id="withOutSpecFile"
              :label="$i18n.t('label.withOutSpecFile')"
              :disabled="loading || !!offer.specification"
              color="privat"
              class="mt-0"
            ></v-checkbox>
          </v-card-text>
          <v-card-actions class="justify-space-between pt-0">
            <v-btn
              id="close"
              color="gray"
              @click="closeAttach"
              width="45%"
              aria-label="close"
              :disabled="loading"
            >
              {{ $t("action.close") }}
            </v-btn>
            <v-btn
              id="send"
              color="privat"
              :loading="loading"
              :disabled="loading || (!withOutSpecFile && !offer.specification)"
              @click="sendAttachFile"
              width="45%"
              aria-label="send file"
            >
              <span class="white--text">{{ $t("action.send") }}</span>
              <template v-slot:loader>
                <v-progress-circular
                  indeterminate
                  :size="22"
                  :width="2"
                  :value="75"
                  color="white"
                ></v-progress-circular>
              </template>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center" id="showMeeting">
      <v-dialog
        v-model="showMeeting"
        persistent
        min-width="320px"
        max-width="500px"
      >
        <v-card class="pa-3" min-height="250px">
          <v-card-title class="text-h6 px-2 pt-2 pb-4">
            {{ $t("offer.createMeeting") }}
          </v-card-title>
          <span class="px-2 pb-0 text--black">
            {{ $t("offer.addressMeeting") }}
          </span>
          <v-card-text class="px-2 pb-0">
            <BaseSelectAddress
              :validate="needValidate"
              :loading="loading"
              :size-full="true"
              :clear="toggleClearAddress"
              :load-region="toggleLoadRegion"
              @getAddrId="getAddressId"
              @clearAddrId="clearAddrId"
              @error="errorFromChild"
            />
            <v-divider class="mb-6"></v-divider>
            <v-select
              v-model="meeting.person"
              :items="possiblePeopleToMeet"
              :label="$t('label.meetingClient')"
              :rules="rules.meetingPerson"
              :disabled="loading || possibleReMakeMeet"
              color="privat"
              outlined
              dense
            >
              <template v-slot:selection="{ item }">
                {{ item.name }}
              </template>
              <template v-slot:item="{ item, attrs, on }">
                <v-list-item v-on="on" v-bind="attrs">
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-row no-gutters align="center">
                        <span>{{ item.name }}</span>
                      </v-row>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-select>
          </v-card-text>
          <v-card-text
            class="d-flex justify-space-between px-2 pb-1 meeting-pikers"
          >
            <v-dialog
              ref="datePicker"
              v-model="showMeetingDatePiker"
              :close-on-content-click="false"
              max-width="290px"
              min-width="290px"
              persistent
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="computedDateFormattedMoment"
                  :label="$i18n.t('label.meetingDate')"
                  :rules="rules.meetingDate"
                  :disabled="loading || possibleReMakeMeet"
                  append-icon="mdi-calendar"
                  readonly
                  outlined
                  dense
                  v-bind="attrs"
                  v-on="on"
                  @click:clear="clearDate"
                ></v-text-field>
              </template>
              <v-date-picker
                id="filterDate"
                v-model="meetingDate"
                :min="minDate"
                :locale="langDatePiker"
                :first-day-of-week="1"
                @change="closeDatePiker"
                color="privat"
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="clearDate">
                  {{ $t("action.cancel") }}
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.datePicker.save(meetingDate)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>

            <v-dialog
              ref="time"
              v-model="showMeetingTimePiker"
              :close-on-content-click="false"
              :return-value.sync="meetingTime"
              max-width="290px"
              min-width="290px"
              persistent
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="meetingTime"
                  :label="$i18n.t('label.meetingTime')"
                  :rules="rules.meetingDate"
                  :disabled="loading || possibleReMakeMeet"
                  append-icon="mdi-clock-time-four-outline"
                  readonly
                  outlined
                  dense
                  v-bind="attrs"
                  v-on="on"
                  @click:clear="clearTime"
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="showMeetingTimePiker"
                v-model="meetingTime"
                @change="closeTimePiker"
                format="24hr"
                color="privat"
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="clearTime">
                  {{ $t("action.cancel") }}
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.time.save(meetingTime)"
                >
                  OK
                </v-btn>
              </v-time-picker>
            </v-dialog>
          </v-card-text>
          <v-card-text class="px-2 pb-1">
            <v-checkbox
              v-model="withOutMeeting"
              id="withOutSpecFile"
              :label="$i18n.t('label.withOutMeeting')"
              :disabled="loading"
              color="privat"
              class="mt-0"
            ></v-checkbox>
          </v-card-text>
          <v-card-actions class="justify-space-between pt-0">
            <v-btn
              id="closeMeeting"
              color="gray"
              @click="closeMeeting"
              :disabled="loading"
              width="45%"
              aria-label="close"
            >
              {{ $t("action.close") }}
            </v-btn>
            <v-btn
              id="sendMeeting"
              color="privat"
              @click="possibleReMakeMeet ? reMakeMeeting() : sendMeeting()"
              :disabled="
                loading ||
                  (timeReMakeMeet !== 0 && possibleReMakeMeet) ||
                  disabledSendMeeting
              "
              width="45%"
              aria-label="send file"
            >
              <span class="white--text d-inline-block mr-3">
                {{ $t("action.send") }}
              </span>
              <span
                class="black--text"
                v-if="timeReMakeMeet !== 0 && possibleReMakeMeet"
              >
                {{ timeReMakeMeet }}
              </span>
              <template v-slot:loader>
                <v-progress-circular
                  indeterminate
                  :size="22"
                  :width="2"
                  :value="75"
                  color="white"
                ></v-progress-circular>
              </template>
            </v-btn>
          </v-card-actions>
          <v-progress-circular
            v-if="loading"
            :size="35"
            color="privat"
            indeterminate
          ></v-progress-circular>
        </v-card>
      </v-dialog>
    </v-row>

    <base-snackbar :props="snack" :show="show" @close="show = false" />
  </v-container>
</template>

<script>
import showSnack from "@/mixins/showSnack.mixins";
import OfferStepZero from "@/components/offer/OfferStepZero";
import OfferStepOne from "@/components/offer/OfferStepOne";
import OfferStepTwo from "@/components/offer/OfferStepTwo";
import OfferStepThree from "@/components/offer/OfferStepThree";
import OfferStepFour from "@/components/offer/OfferStepFour";
import OfferStepFive from "@/components/offer/OfferStepFive";
import { mapGetters } from "vuex";
import termFilter from "@/filters/term.filter";
import errorHandler from "@/utils/errorHandler";
import CONST from "@/utils/constants";
import { sendFile, sendToBank, getPdf } from "@/services/orders.service";
import * as offerService from "@/services/offer.service";
import { interactionDataAddress } from "@/services/settings.services";
import BaseSelectAddress from "@/components/BaseSelectAddress";
import moment from "moment";
import { makeMeeting } from "@/services/offer.service";
import checkFileType from "@/utils/checkFileType";
import createMockData from "@/mock/mockNewData";

export default {
  name: "offer",
  mixins: [showSnack],
  provide() {
    return { attachFile: this.attachFile };
  },
  components: {
    OfferStepZero,
    OfferStepOne,
    OfferStepTwo,
    OfferStepThree,
    OfferStepFour,
    OfferStepFive,
    BaseSelectAddress
  },
  data() {
    return {
      loading: false,
      loadingOptions: false,
      valid: true,
      step: 0,
      stepSellerText: false,
      offer: {
        program: null,
        client: {
          okpo: null,
          phoneId: null,
          mailId: null,
          near: false,
          identified: false,
          sentPhone: false,
          codeOtp: null,
          otpSuccess: false,
          phones: [],
          emails: [],
          orgName: "",
          cpId: null
        },
        pledgeDetails: [],
        generalParams: {
          term: "",
          schedule: "",
          adaptMonths: "",
          firstPaymentDate: moment().format("YYYY-MM-DD"),
          rate: null,
          onceCommission: "",
          stateGuarantee: false,
          editGuarantee: false,
          urid: "",
          typeCompens: null,
          percentComp: null
        },
        specification: null,
        pdfBase64: "",
        nrmDecision: null
      },
      savedOffer: {},
      overlay: false,
      progressValue: 0,
      progressQuery: false,
      progressShow: false,
      progressInterval: 0,
      showAttach: false,
      showMeeting: false,
      formOptions: {
        program579s: []
      },
      nrmOptions: {},
      withOutSpecFile: false,
      disabledSendToBank: false,
      meeting: {
        addressId: null,
        date: null,
        person: null,
        time: null
      },
      needValidate: false,
      langDatePiker: null,
      minDate: moment().format("YYYY-MM-DD"),
      meetingDate: null,
      showMeetingDatePiker: false,
      meetingTime: null,
      showMeetingTimePiker: false,
      toggleClearAddress: false,
      toggleLoadRegion: false,
      possiblePeopleToMeet: [],
      possibleReMakeMeet: false,
      timeReMakeMeet: true,
      withOutMeeting: false,
      isMeetRequired: false,
      cashRate: null,
      typesCompensation: CONST.TYPES_COMPENSATION
    };
  },
  computed: {
    ...mapGetters([
      "getOfferOptions",
      "getUser",
      "getStatus",
      "getPointOfEntry",
      "getOrdersAndSettingsOptions",
      "devEnv"
    ]),
    stepClientNear() {
      if (this.offer.program.includes("CR")) {
        return [
          this.$i18n.t("offer.step.one"),
          this.$i18n.t("offer.step.two_CR"),
          this.$i18n.t("offer.step.three"),
          this.$i18n.t("offer.step.four")
        ];
      } else {
        return [
          this.$i18n.t("offer.step.one"),
          this.$i18n.t("offer.step.two"),
          this.$i18n.t("offer.step.three"),
          this.$i18n.t("offer.step.four")
        ];
      }
    },
    stepClientNotNear() {
      if (this.offer.program.includes("CR")) {
        return [
          this.$i18n.t("offer.step.one"),
          this.$i18n.t("offer.step.two_CR"),
          "",
          this.$i18n.t("offer.step.threeClientNotNear")
        ];
      } else {
        return [
          this.$i18n.t("offer.step.one"),
          this.$i18n.t("offer.step.two"),
          "",
          this.$i18n.t("offer.step.threeClientNotNear")
        ];
      }
    },
    rules() {
      return {
        email: [v => !!v || this.$i18n.t("rules.email.choose")],
        spec: [
          v =>
            v.size <= CONST.MAX_FILE_SIZE ||
            this.$i18n.t("rules.file.max_size"),
          v => checkFileType(v) || this.$i18n.t("rules.file.bad_type")
        ],
        meetingPerson: [v => !!v || this.$i18n.t("rules.required")],
        meetingDate: [v => !!v || this.$i18n.t("rules.required")],
        meetingTime: [v => !!v || this.$i18n.t("rules.required")]
      };
    },
    cpId() {
      return this.offer.client.cpId;
    },
    authEsa() {
      return this.getPointOfEntry;
    },
    disabledSendMeeting() {
      if (this.withOutMeeting) {
        return false;
      }
      return (
        !this.meeting.addressId ||
        !this.meeting.person ||
        !this.meeting.date ||
        !this.meeting.time
      );
    },
    computedDateFormattedMoment() {
      return this.meetingDate
        ? moment(this.meetingDate).format("DD.MM.YYYY")
        : null;
    },
    protector() {
      return this.savedOffer?.protector;
    }
  },
  methods: {
    showProgress(data) {
      this.overlay = !this.overlay;
      data ? this.queryAndIndeterminate() : clearInterval(this.overlayInterval);
    },
    goToStep(data) {
      let [step, overlay] = data;

      overlay ? (this.overlay = !this.overlay) : false;
      if (step === 2) {
        this.stepSellerText = false;
        this.getFormOptions();
      } else {
        this.step = step;
      }
      clearInterval(this.overlayInterval);
    },
    queryAndIndeterminate() {
      this.progressQuery = true;
      this.progressShow = true;
      this.progressValue = 0;

      this.localTimeout = setTimeout(() => {
        this.progressQuery = false;

        this.overlayInterval = setInterval(() => {
          if (this.progressValue < 99) {
            this.progressValue += 0.5;
          } else {
            this.progressValue = 0;
          }
        }, 200);
      }, 2000);
    },
    stepBack() {
      if (this.offer.nrmDecision !== "TERMS") {
        this.goToStep([3, false]);
      } else {
        this.goToStep([2, false]);
      }
    },
    saveOffer() {
      this.showProgress(true);
      offerService
        .saveOffer(this.cpId, this.authEsa)
        .then(res => {
          this.savedOffer = res.data.cp;
          this.$store.dispatch("setOfferStatus", "finish");
          this.goToStep([5, true]);
        })
        .catch(err => {
          let errorCode = errorHandler(err, "saveOffer");

          this.showSnack("error", [this.$i18n.t(`error.${errorCode}`)]);
          this.showProgress(false);
          console.log("catch saveOffer");
        });
    },
    getNeedMeeting() {
      offerService
        .needMeeting(this.offer.client.cpId)
        .then(res => {
          let data = res.data;

          if (data.isMeetRequiredCp) {
            this.isMeetRequired = true;
            this.possiblePeopleToMeet = data.links.map(el => {
              return {
                ...el,
                value: el.ekbId
              };
            });
            this.toggleLoadRegion = !this.toggleLoadRegion;
            this.showMeeting = true;
            this.loading = false;
          } else {
            this.sendOfferToBank();
          }
        })
        .catch(err => {
          let errorCode = errorHandler(err);

          this.showSnack("error", [this.$i18n.t(`error.${errorCode}`)]);
          this.closeAttach();
          this.loading = false;
          console.log("catch getNeedMeeting");
        });
    },
    checkAttachFile() {
      this.loading = true;
      if (!this.offer.specification) {
        this.showAttach = true;
        this.loading = false;
      }
      if (!this.authEsa && this.offer.specification) {
        this.sendOfferToBank();
      }
      if (this.authEsa && this.offer.specification) {
        this.getNeedMeeting();
      }
    },
    sendAttachFile() {
      if (!this.withOutSpecFile && !checkFileType(this.offer.specification)) {
        return false;
      }
      this.loading = true;
      if (this.withOutSpecFile) {
        if (this.authEsa) {
          this.getNeedMeeting();
        } else {
          this.sendOfferToBank();
        }

        return;
      }
      if (this.offer.specification) {
        let formData = new FormData();

        formData.append("cpId", this.cpId);
        formData.append("file", this.offer.specification);
        sendFile(formData, this.authEsa)
          .then(() => {
            this.showSnack("success", [this.$i18n.t("success.sendAttachFile")]);
            if (this.authEsa) {
              this.getNeedMeeting();
            } else {
              this.sendOfferToBank();
            }
          })
          .catch(err => {
            let errorCode = errorHandler(err, "sendAttachFile");

            this.showSnack("error", [this.$i18n.t(`error.${errorCode}`)]);
            this.closeAttach();
            this.loading = false;
            console.log("catch sendAttachFile");
          });
      }
    },
    closeAttach() {
      this.showAttach = false;
      this.withOutSpecFile = false;
      this.offer.specification = null;
    },
    async sendOfferToBank() {
      let params = "";
      let meet = this.meeting;

      this.loading = true;
      if (this.authEsa) {
        if (this.isMeetRequired) {
          if (this.withOutMeeting) {
            params = `cpId=${this.cpId}&meetSelect=false`;
          } else {
            params = `cpId=${this.cpId}&addrId=${meet.addressId}&ekbId=${meet.person}&meetTime=${meet.date} ${meet.time}&meetSelect=true`;
          }
        } else {
          params = `cpId=${this.cpId}`;
        }
      } else {
        params = `cpId=${this.cpId}`;
      }
      await sendToBank(params, this.authEsa)
        .then(() => {
          this.showSnack("success", [this.$i18n.t("success.sendOfferToBank")]);

          this.disabledSendToBank = true;
          this.closeAttach();
          this.closeMeeting();
          this.localTimeout = setTimeout(() => {
            this.$store.dispatch("setOfferStatus", "");
            this.$router.push("/orders");
          }, 1000);
        })
        .catch(err => {
          let errorCode = errorHandler(err, "sendOfferToBank");

          if (errorCode === "SERVICE_CRM_ERROR") {
            this.possibleReMakeMeet = true;
            this.disabledSendToBank = false;
            this.startTimeReMakeMeet(15);
          } else {
            this.disabledSendToBank = false;
            this.closeMeeting();
          }
          this.closeAttach();

          this.showSnack("error", [this.$i18n.t(`error.${errorCode}`)]);
          console.log("catch sendOfferToBank");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    startTimeReMakeMeet(time) {
      this.timeReMakeMeet = time;
      this.timeInterval = setInterval(() => {
        if (this.timeReMakeMeet === 0) {
          clearInterval(this.timeInterval);

          return;
        }
        if (this.timeReMakeMeet >= 1) {
          this.timeReMakeMeet--;
        }
      }, 1000);
    },
    getFormOptions() {
      if (this.devEnv) {
        const data = createMockData("defaultsEsaCp");

        this.createFormOptions(data);
        // mock
        return;
      }

      this.$store
        .dispatch("getOfferDefaultOptions", {
          cpId: this.cpId,
          authEsa: this.authEsa
        })
        .then(() => {
          let data = this.getOfferOptions;

          if (data) {
            this.createFormOptions(data);
          } else {
            let errorCode = this.getStatus || "getDefaultOptions";

            this.showSnack("error", [this.$i18n.t(`error.${errorCode}`)]);
            console.log("then getFormOptions error - ", errorCode);
          }
        })
        .catch(err => {
          let errorCode = errorHandler(err, "getDefaultOptions");

          this.showSnack("error", [this.$i18n.t(`error.${errorCode}`)]);
          console.log("catch getOfferDefaultOptions");
        })
        .finally(() => {
          this.loadingOptions = false;
        });
    },
    createFormOptions(data) {
      this.createFormCompensation(data.typeCompens, "typesCompensation");
      this.createFormOptionsToView(data.conditionTypes, "conditionTypes");
      this.createFormObjectDetails(data.objectDetails, "objectDetails");
      this.createFormOptionsToView(data.schedules, "schedules");
      this.createFormPrepayment(data.prepaymentTypes, "prepaymentTypes");
      this.createFormPeriod(data.periodTypes, "periodTypes");
      this.createFormAdaptDetails(data.adaptDetails, "adaptDetails");
      this.createFormUirdTypes(data.uirdTypes, "uirdTypes");
      this.setPropsGuarantee(data);
      if (this.cashRate) {
        this.offer.generalParams.rate = this.cashRate;
        this.formOptions.rate = this.cashRate;
      } else {
        this.offer.generalParams.rate = null; //data.defaultStake;
        this.formOptions.rate = null; //data.defaultStake;
      }

      this.stepSellerText = false;
      this.step = 2;
    },
    createFormCompensation(data, props) {
      this.formOptions[props] = data.map(el => {
        let elValue = el?.value ? el.value : el;
        let tooltip = false;
        let disabled = false;

        return {
          value: elValue,
          label: this.$i18n.t(`options.${props}.${elValue}`),
          disabled: disabled,
          tooltip: tooltip
        };
      });
    },
    createFormObjectDetails(data, props) {
      this.formOptions[props] = data
        .map(obj => {
          return Object.assign(obj, {
            label: this.$i18n.t(`options.${props}.${obj.value}`),
            disabled: false,
            regNew: {
              show: obj.flRegNew !== "NO",
              disabled: obj.flRegNew === "YES",
              value: obj.flRegNew === "YES",
              sum: obj.sumRegNew?.toFixed(2)
            },
            regSh: {
              show: obj.flRegSh !== "NO",
              disabled: obj.flRegSh === "YES",
              value: obj.flRegSh === "YES",
              sum: obj.sumRegSh?.toFixed(2)
            },
            PFNew: {
              show: obj.flPfNew !== "NO",
              disabled: obj.flPfNew === "YES",
              value: obj.flPfNew === "YES",
              sum: null
            },
            PFSh: {
              show: obj.flPfSh !== "NO",
              disabled: obj.flPfSh === "YES",
              value: obj.flPfSh === "YES",
              sum: null
            }
          });
        })
        .sort(function(a, b) {
          if (a.nameDesc > b.nameDesc) return 1;
          if (a.nameDesc < b.nameDesc) return -1;

          return 0;
        });
    },
    createFormOptionsToView(data, props) {
      this.formOptions[props] = data.map(obj => {
        let tooltip = false;
        let disabled = false;

        return Object.assign(obj, {
          label: this.$i18n.t(`options.${props}.${obj.value}`),
          disabled: disabled,
          tooltip: tooltip
        });
      });
    },
    createFormPrepayment(data, props) {
      this.formOptions[props] = data.map(obj => {
        return Object.assign(obj, {
          _val: obj.value.replace("P", ""),
          label: `${obj.value.replace("P", "")} %`
        });
      });
    },
    createFormPeriod(data, props) {
      this.formOptions[props] = data.map(obj => {
        return Object.assign(obj, {
          label: termFilter(obj.value, this.$i18n)
        });
      });
    },
    createFormAdaptDetails(data, props) {
      this.formOptions[props] = data.map((obj, i) => {
        return Object.assign(obj, {
          _val: ++i,
          label: this.$i18n.t(`options.${props}.${obj.value}`)
        });
      });
    },
    createFormUirdTypes(data, props) {
      this.formOptions[props] = data.map(obj => {
        return Object.assign(obj, {
          value: obj.code,
          label: `${obj.description}`
        });
      });
    },
    setPropsGuarantee(data) {
      this.offer.generalParams.stateGuarantee = data.guarantee;
      this.offer.generalParams.editGuarantee = data.editGuarantee;
    },
    updateOffer(data) {
      let [subject, general, nextStep] = data;

      this.offer.pledgeDetails = [].concat(subject);
      this.offer.generalParams = Object.assign(
        this.offer.generalParams,
        general
      );
      this.cashRate = +this.offer.generalParams.rate;
      if (nextStep) {
        this.getNRMService();
      }
    },
    updateFinancing(data) {
      offerService
        .updateFinancing(this.cpId, data.percent, data.term, this.authEsa)
        .then(() => {
          this.getPdfReport();
        })
        .catch(err => {
          let errorCode = errorHandler(err, "other");

          this.showProgress(false);
          this.showSnack("error", [this.$i18n.t(`error.${errorCode}`)]);
          console.log("catch updateFinancing");
        });
    },
    getNRMService() {
      offerService
        .getNRM(this.cpId, this.authEsa)
        .then(res => {
          this.checkStateNRM(res.data);
        })
        .catch(err => {
          let errorCode = errorHandler(err);

          this.showSnack("error", [this.$i18n.t(`error.${errorCode}`)]);
          this.showProgress(false);
          console.log("catch getNRMService");
        });
    },
    checkStateNRM(data) {
      let decision = data.decision;

      this.offer.nrmDecision = decision;
      if (decision === "TERMS") {
        this.getPdfReport();
      } else {
        this.nrmOptions = data;
        this.step = 3;
        this.showProgress(false);
      }
    },
    getPdfReport(data) {
      if (data) {
        offerService
          .getPdfDesire(this.cpId, this.authEsa)
          .then(res => {
            this.offer.pdfBase64 = res.data;
          })
          .catch(err => {
            let errorCode = errorHandler(err, "loadPdf");

            this.showSnack("error", [this.$i18n.t(`error.${errorCode}`)]);
            console.log("catch getPdfReport desire");
          })
          .finally(() => {
            this.showProgress(false);
            this.step = 4;
          });
      } else {
        getPdf(this.cpId, this.authEsa)
          .then(res => {
            this.offer.pdfBase64 = res.data;
          })
          .catch(err => {
            let errorCode = errorHandler(err, "loadPdf");

            this.showSnack("error", [this.$i18n.t(`error.${errorCode}`)]);
            console.log("catch getPdfReport");
          })
          .finally(() => {
            this.showProgress(false);
            this.step = 4;
          });
      }
    },
    clearAddrId() {
      this.meeting.addressId = null;
    },
    errorFromChild(error) {
      this.showSnack("error", error);
    },
    getAddressId(modelAd) {
      let url = this.createUrlAddress(modelAd);

      this.isLoading = true;
      interactionDataAddress(url, "POST")
        .then(res => {
          this.meeting.addressId = res.data;
        })
        .catch(err => {
          let errorCode = errorHandler(err, "other");

          this.showSnack("error", [this.$i18n.t(`error.${errorCode}`)]);
          console.log("catch getAddressId");
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    createUrlAddress(modelAd) {
      let corpus = modelAd.corpus ? `&corpus=${modelAd.corpus}` : "";
      let flat = modelAd.flat ? `&flat=${modelAd.flat}` : "";
      let idStreet = modelAd.street.code;
      let building = modelAd.building.desc;

      return `esa/addr/updateWCheck?id=${idStreet}&house=${building}${corpus}${flat}`;
    },
    sendMeeting() {
      if (this.withOutMeeting) {
        this.sendOfferToBank();
      } else {
        this.needValidate = !this.needValidate;
      }

      let dateNow = moment().format("YYYY-MM-DD");
      let timeNow = moment().format("HH:mm");

      if (dateNow === this.meeting.date && timeNow > this.meeting.time) {
        this.showSnack("error", [
          this.$i18n.t(`error.WEB_REQUEST_DATA_INVALID`)
        ]);
        this.meeting.time = null;
        this.meetingTime = null;

        return;
      }

      if (
        this.meeting.addressId &&
        this.meeting.person &&
        this.meeting.time &&
        this.meeting.date
      ) {
        this.sendOfferToBank();
      }
    },
    reMakeMeeting() {
      this.disabledSendToBank = true;
      this.loading = true;
      makeMeeting(this.cpId)
        .then(res => {
          console.log("reMakeMeeting", res.data);
          this.closeMeeting();
        })
        .catch(err => {
          let errorCode = errorHandler(err);

          this.showSnack("error", [this.$i18n.t(`error.${errorCode}`)]);
          this.startTimeReMakeMeet(25);
          console.log("catch reMakeMeeting");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    closeMeeting() {
      this.showMeeting = false;
      this.withOutMeeting = false;
      this.meeting.person = null;
      this.meeting.addressId = null;
      this.toggleClearAddress = !this.toggleClearAddress;
      this.clearDate();
      this.clearTime();
    },
    clearDate() {
      this.meeting.date = null;
      this.meetingDate = null;
      this.showMeetingDatePiker = false;
    },
    closeDatePiker() {
      this.meeting.date = this.meetingDate;
    },
    clearTime() {
      this.meeting.time = null;
      this.meetingTime = null;
      this.showMeetingTimePiker = false;
    },
    closeTimePiker() {
      this.meeting.time = this.meetingTime;
    },
    setFinancing(data) {
      this.offer.program = data;
      this.step = 1;
    },
    updateFormOptions(data) {
      this.createFormOptionsToView([data], "program579s");
    }
  },
  mounted() {
    this.langDatePiker = CONST.LOCALE[this.$i18n.locale].toLowerCase();
  },
  created() {
    if (this.getStatus !== "create") {
      this.$router.push("/orders");
    }
  },
  beforeDestroy() {
    clearInterval(this.timeInterval);
    clearInterval(this.overlayInterval);
    clearTimeout(this.localTimeout);
  }
};
</script>

<style lang="scss">
#offer {
  @media (min-width: 960px) {
    max-width: 95%;
  }
  .container {
    @media (min-width: 960px) {
      max-width: 100%;
    }
  }
}
#form-offer {
  position: relative;
  [class*="col"] {
    padding: 1px 12px;
  }
  .v-progress-linear {
    width: 90%;
    @media (min-width: 600px) {
      width: 75%;
    }
  }
  .v-overlay--active.isOpacity {
    .v-overlay__scrim {
      opacity: 1 !important;
    }
  }
  .v-overlay__scrim {
    background-color: #fff !important;
  }
  .wrap-progress {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 320px;
    height: 50vh;
    margin-bottom: 250px;
    box-shadow: none;
    @media (min-width: 600px) {
      width: 540px;
    }
  }
}
#showAttach {
  .v-card-title {
    word-break: break-word;
  }
}
</style>
